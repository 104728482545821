<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="justify-content-center">
      <CCol md="8">
        <CCardGroup>

          <CCard
            text-color="white"
            class="text-center py-5"
            style="width:500px;background-color:#409EFF"
            body-wrapper
          >
            <h3>月光法寶管理系統</h3>
            <!--
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            <CButton
              color="primary"
              class="active mt-3"
            >
              Register Now!
            </CButton>
            -->
          </CCard>
                    <CCard class="p-4">
            <CCardBody>
              <CForm>
                <h1>登入</h1>
                <!-- <p class="text-muted">Sign In to your account</p> -->
                <CInput
                  placeholder="帳號"
                  autocomplete="username email"
                  v-model="form.username"
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                <CInput
                  placeholder="密碼"
                  type="password"
                  autocomplete="curent-password"
                  v-model="form.password"
                >
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </CInput>
                <CRow>
                  <CCol col="6">
                    <el-button type="primary" class="px-4" @click="login">登入</el-button>
                  </CCol>
                  <!--
                  <CCol col="6" class="text-right">
                    <CButton color="link" class="px-0">Forgot password?</CButton>
                  </CCol>
                  -->
                </CRow>
              </CForm>
            </CCardBody>
          </CCard>
        </CCardGroup>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      form: {
        username:'',
        password:''
      }
    }
  },
  methods:{
    login(){
      this.$store.dispatch('auth/login', this.form, {
          root: true
      }).then(res => {
        if(this.$store.state.auth.isSignIn) {
          this.$router.replace('/');
        }
      })
    }
  }
}
</script>
